<template>
  <el-card class="box-card">
    <div class="warn">
      请自觉遵守微信关于规范外部链接内容管理的规范，防止由于微信封号导致不必要的损失。
      <el-link
          href="http://weixin.qq.com/cgi-bin/readtemplate?t=weixin_external_links_content_management_specification"
          target="_blank"
          style="margin-top: -5px"
      >
        点击查看详情
      </el-link>
    </div>
    <el-form ref="form" label-width="130px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="活动名称" prop="name">
        <el-tooltip placement="top">
          <div slot="content">活动名称将显示在活动列表中，发布商家管理</div>
          <el-input v-model="form.name" placeholder="请输入活动名称" size="small" style="width: 25%"/>
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动时间" prop="begin_time">
        <el-date-picker
            v-model="query_time"
            size="small"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
            @change="getTimeSection"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否参与分销" prop="is_join_distribution">
        <el-radio-group v-model="form.is_join_distribution" size="small">
          <el-radio-button label="0">参与</el-radio-button>
          <el-radio-button label="1">不参与</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="购买方式" prop="distribution">
        <el-radio-group v-model="form.distribution" size="small">
          <el-radio-button label="0">任意金额可购买</el-radio-button>
          <el-radio-button label="1">砍到指定金额才可购买</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="帮砍金额" prop="chop_money">
        <el-radio-group v-model="form.chop_money" size="small" @change="moneyType">
          <el-radio-button label="0">随机金额</el-radio-button>
          <el-radio-button label="1">固定金额</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.chop_money == 1" style="margin-bottom: 20px" label="固定砍价金额" prop="fixed_money">
        <el-input type="number" v-model="form.fixed_money" placeholder="请输入金额" style="width: 25%" size="small">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否允许自己砍价" prop="is_oneself_chop">
        <el-switch
            v-model="form.is_oneself_chop"
            active-color="#13ce66"
            active-text="允许"
            active-value="1"
            inactive-color="#ff4949"
            inactive-text="不允许"
            inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="砍价人数" prop="chop_number">
        <el-tooltip placement="top">
          <div slot="content">每个用户同一件商品只可砍一刀<br/>帮砍人数为该砍价订单最少人数</div>
          <el-input-number
              v-model="form.chop_number"
              :step="1"
              step-strictly
              :min="1"
              controls-position="right"
              size="small"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="砍价有效时间" prop="chop_validity">
        <el-tooltip placement="top">
          <div slot="content">自用户发起砍价到砍价截止时间</div>
          <el-input-number
              v-model="form.chop_validity"
              :step="1"
              step-strictly
              :min="1"
              controls-position="right"
              size="small"
              style="float: left"
          />
        </el-tooltip>
        <div style="float: left; margin: -4px 0 0 10px">小时</div>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动商品" prop="">
        <el-table :data="s_list" size="small" style="width: 70%"
                  @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection"/>
          <el-table-column
              align="center"
              label="商品图片"
              prop="imgUrl"
          >
            <template #default="{row}">
              <el-image v-if="row.imgUrl" :src="row.imgUrl" class="commodity_pic"/>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="price"
          />
          <el-table-column
              align="center"
              label="库存"
              prop="stock"
          />
          <el-table-column
              align="center"
              label="首刀金额"
              prop="first_knife"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.first_knife" v-model="s_list[scope.$index].first_knife"
                        placeholder="请输入内容" :value="scope.row.first_knife"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].first_knife" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="砍价库存"
              prop="inventory"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.inventory" v-model="s_list[scope.$index].inventory"
                        placeholder="请输入内容" :value="scope.row.inventory"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].inventory" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="底价"
              prop="low_price"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.low_price" v-model="s_list[scope.$index].low_price"
                        placeholder="请输入内容" :value="scope.row.low_price"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].low_price" placeholder="请输入内容" :value="0"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" type="primary" @click="showPop = true" size="small" style="margin-top: 10px">
          选择商品
        </el-button>
        <el-button v-if="s_list.length > 0" icon="el-icon-delete" type="danger" style="margin-top: 10px"
                   @click="handleDelete">批量删除
        </el-button>
        <el-button v-else icon="el-icon-delete" type="danger" size="small" style="margin-top: 10px" disabled>批量删除
        </el-button>
        <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动规则说明" prop="validity">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="form.validity"
            style="width: 25%"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>

import PopView from "@/views/components/popView";
import {apiGetBargainingInfo, apiUpdateBargaining} from "@/request/api";
import {formatDate} from "@/utils/theTimeStamp";

export default {
  components: {PopView},
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPop: false,
      s_list: [],
      query_time: [],
      form: {
        id: '',
        name: '',
        begin_time: '',
        end_time: '',
        is_join_distribution: '1',
        distribution: '0',
        chop_money: '0',
        is_oneself_chop: '0',
        chop_number: '',
        fixed_money: '',
        chop_validity: '',
        validity: '',
        goods: [],
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '活动名称不能为空'},
        ],
        validity: [
          {required: true, trigger: 'blur', message: '规则说明不能为空'},
        ],
        begin_time: [
          {required: true, trigger: 'blur', message: '活动时间不能为空'},
        ],
        chop_number: [
          {required: true, trigger: 'blur', message: '砍价人数不能为空'},
        ],
        chop_validity: [
          {required: true, trigger: 'blur', message: '砍价有效时间不能为空'},
        ],
      },
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getBargainInfo()
    }
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    moneyType(event) {
      if (event != 1) {
        this.form.fixed_money = ''
      }
    },
    getBargainInfo() {
      apiGetBargainingInfo({id: this.$route.query.id}).then(res => {
        if (res.code == 200) {
          console.log(res.data)
          this.form = {
            id: res.data.id,
            name: res.data.name,
            begin_time: this.formatDate(res.data.begin_time),
            end_time: this.formatDate(res.data.end_time),
            is_join_distribution: res.data.is_join_distribution,
            distribution: res.data.distribution,
            chop_money: res.data.chop_money,
            is_oneself_chop: res.data.is_oneself_chop,
            chop_number: res.data.chop_number,
            fixed_money: res.data.fixed_money,
            chop_validity: res.data.chop_validity,
            validity: res.data.validity,
            goods: this.getGoods(res.data.goods),
          }
          this.query_time = [this.formatDate(res.data.begin_time), this.formatDate(res.data.end_time)]
        }
      })
    },
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd')
    },
    getGoods(arr) {
      this.list = []
      if (arr.length > 0) {
        arr.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            first_knife: item.first_knife,//首刀金额
            inventory: item.stock,//参与砍价库存
            low_price: item.price,//低价
          })
          this.s_list.push({
            sku_id: item.sku_info.skuid,
            imgUrl: item.sku_info.imgUrl,//商品图片
            name: item.sku_info.name,//商品名
            price: item.sku_info.price,//价格
            stock: item.sku_info.stock,//库存
            first_knife: item.first_knife,//首刀金额
            inventory: item.inventory,//参与砍价库存
            low_price: item.low_price,//低价
          })
        })
        return this.list
      }
    },
    showPopSubmit(e) {
      this.showPop = true;
      console.log(e)
      if (e) {
        e.forEach(item => {
          this.s_list.push({
            sku_id: item.skuid,
            imgUrl: item.gallery,//商品图片
            name: item.name,//商品名
            price: item.price,//价格
            stock: item.stock,//库存
            first_knife: 0.1,//首刀金额
            inventory: item.stock,//参与砍价库存
            low_price: item.price,//低价
          })
        })
        const smap = new Map()
        // 对id属性进行去重
        this.s_list = this.s_list.filter(key => !smap.has(key.sku_id) && smap.set(key.sku_id, 1))
        this.listLoading = false
      }
    },
    //删除选中商品
    handleDelete(row) {
      if (row.sku_id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.s_list.map((val, i) => {
            if (val.sku_id == row.sku_id) {
              this.s_list.splice(i, 1)
            }
          })
        })
      } else {
        this.$confirm('你确定要删除所有选中项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var j = 0; j < this.selectRows.length; j++) {
            this.s_list.map((val, i) => {
              if (val.sku_id == this.selectRows[j].sku_id) {
                this.s_list.splice(i, 1)
              }
            })
          }
        })
      }
    },
    //获取时间段
    getTimeSection(event) {
      if (event != null) {
        this.form.begin_time = event[0]
        this.form.end_time = event[1]
      } else {
        this.form.begin_time = ''
        this.form.end_time = ''
      }
    },
    //清空表单
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.query_time = []
      this.s_list = []
    },
    //提交保存
    async submit() {
      this.list = []
      if (this.s_list.length > 0) {
        this.s_list.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            first_knife: item.first_knife,//首刀金额
            inventory: item.inventory,//参与砍价库存
            low_price: item.low_price,//低价
          })
        })
      } else {
        this.$message.error('参与活动商品不能为空')
        return
      }
      const map = new Map()
      this.form.goods = this.list.filter(key => !map.has(key.sku_id) && map.set(key.sku_id, 1))
      apiUpdateBargaining(this.form).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.close()
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}

</script>
<style>
.warn {
  background-color: #fdf6ec;
  color: #e6a23c;
  padding: 8px 16px;
  font-size: 13px;
  margin-bottom: 30px;
}

.commodity_pic {
  width: 60px;
  height: 60px;
}
</style>
